import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { LinkButton, ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Barcode from 'react-barcode'
import OrderItemPrice from './OrderItemPrice'
import OrderItemPriceV2 from './OrderItemPriceV2'
import { formatAmount } from './../../utilis/PricingUtils'
import { downloadFile } from '../../services/HttpClient'
import Button from '../UI/Button'

const Order = (props) => {
  const KronorConverter = 100
  const version = props.version
  const storeId = props.match.params.storeid

  const orderId = props.orderid ? props.orderid : props.match.params.orderid
  const storeid = props.storeid ? props.storeid : props.match.params.storeid
  const [data, setData] = useState({ orderNo: "" })
  const [readOnly, setReadOnly] = useState()
  const [downloadingReceipt, setDownloadingReceipt] = useState(false)

  if (version !== 2) throw new Error("No support for Order V1")

  useEffect(() => {
    const fetchData = async () => {
      const readOnlyResponse = await getData('api/navigation/roles')
      const readOnly = await readOnlyResponse.isReader
      setReadOnly(readOnly)

      const url = `/api/order/${orderId}/${storeid}/v2`

      const response = await getData(url)
      const result = await response
      if (result) {
        setData(result)
      }
    }

    fetchData()
  }, [])

  function EanBarcode(ean) {
    return <Barcode value={`${ean}`} width={1} height={20} fontSize={10} />
  }

  function GetTax(taxCode) {
    if (taxCode === 1) {
      return 25
    }

    if (taxCode === 2) {
      return 12
    }

    if (taxCode === 3) {
      return 6
    }

    return 0
  }

  function CampaignItem(order) {
    const campaign = order.campaign
    return <div className="list-item">
      <div className="list-item__element">Rabatt</div>
      <div className="list-item__element">{campaign.discountCode}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">-{campaign.campaignValue}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
    </div>
  }

  function DeliveryItem(order) {
    const delivery = order.delivery
    return <div className="list-item">
      <div className="list-item__element">{delivery.type}</div>
      <div className="list-item__element"> {delivery.ean && EanBarcode(delivery.ean)}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{delivery.deliveryFee}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{GetTax(delivery.taxCode)}%</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{delivery.hasBeenReversed ? delivery.deliveryFee : 0}</div>
      <div className="list-item__element"></div>
    </div>
  }

  function OrderItemV2(item) {
    return <div className="list-item">
      <div className="list-item__element">{item.name} {item.bottleDepositPrice > 0 && <div className="list-item__element--small"> {`(+ pant ${formatAmount(item.bottleDepositPrice)})`} </div>} {item.promotionRestriction && <div className="list-item__element--small">({item.promotionRestriction})</div>}</div>
      <div className="list-item__element">
        {item.ean && EanBarcode(item.ean)}
      </div>
      <div className="list-item__element">{item.quantity}</div>
      <div className="list-item__element">{formatAmount(item.unitPrice)}</div>
      <div className="list-item__element">{GetTax(item.taxCode)}%</div>
      <div className="list-item__element whitespace-pre"><OrderItemPriceV2 subTotal={item.totalPrice} discountSubTotal={item.totalDiscount} bottleDepositPrice={item.bottleDepositPrice}></OrderItemPriceV2></div>
      <div className="list-item__element">{formatAmount(item.totalReversal)}</div>
      <div className="list-item__element"><Checkbox checked={item.status > 0} name="active" inputClass="checkbox" disabled /></div>
    </div>
  }

  function OrderItem(item, campaignType) {
    return <div className="list-item">
      <div className="list-item__element">{item.name}</div>
      <div className="list-item__element">
        {item.ean && EanBarcode(item.ean)}
      </div>
      <div className="list-item__element">{item.amount}</div>
      <div className="list-item__element">{item.price}</div>
      <div className="list-item__element">{GetTax(item.taxCode)}%</div>
      <div className="list-item__element"><OrderItemPrice subTotal={item.subTotal} discountSubTotal={item.discountSubTotal} campaignType={campaignType}></OrderItemPrice></div>
      <div className="list-item__element">{item.reversalAmount ? item.reversalAmount / KronorConverter : 0}</div>
      <div className="list-item__element"><Checkbox checked={item.status > 0} name="active" inputClass="checkbox" disabled /></div>
    </div>
  }

  const handleResendRecipt = async (event) => {
    event.persist()
    var haveSent = false
    if (!haveSent) {
      haveSent = true
      const response = await postData(
        `/api/orders/${orderId}/${storeid}/resendreceipt`
      )
      await response
    } else {
      console.error("url not defined")
    }
  }

  const handleDownloadRecipt = async (event) => {
    event.persist()
    setDownloadingReceipt(true)

    const res = await downloadFile(`/api/orders/${orderId}/${storeid}/downloadreceipt`)

    if (!res.ok) {
      alert("Ett fel uppstod vid nerladdning av filen.")
    } 
    setDownloadingReceipt(false)
  }

  return (
    <ProntoContainerTop>
      <BackButton backLabel={Placeholder.Orders} disabled={false} {...props} to={`/stores/${storeId}/orders`}>{`${Placeholder.Order} ${data.orderNo}`}</BackButton>
      <div className="flex-right btn-control--absolut btn-control--absolut__top">
        {(!readOnly && data.userEmail !== null) && <ClickButton onClick={handleResendRecipt} className="btn-control btn--white"
          title={`Skicka kvitto till kund`} />}
        {(!readOnly) && <Button type='cancel' onClick={handleDownloadRecipt} loading={downloadingReceipt}> Ladda ner kvitto/orderbekräftelse</Button>}
        {!readOnly && <LinkButton to={`${props.history.location.pathname}/reversal`} className="btn-control btn--white"
          title={`Hantera återbetalningar`} />}
      </div>

      <ul className="no-style bg-grey">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">Namn</div>
            <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
            <div
              className="list-item__element label hidden-down">Antal {data && data.totalItems && `(${data.totalItems} st)`}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Price}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Moms}</div>

            <div
              className="list-item__element label hidden-down">Total ({formatAmount(data.totalPrice)})</div>
            <div className="list-item__element label hidden-down">Återbetalat ({formatAmount(data.totalReversalAmount)})</div>
            <div className="list-item__element label hidden-down">Plockad</div>

          </div>
        </li>
        {data && data.orderItems && data.orderItems.map((item, index) => {
          if (item.quantity > 0) {
            return (
              <li key={index}>
                {OrderItemV2(item)}
              </li>
            )
          }})}
        {data && data.orderItemsFood && data.orderItemsFood.map((item, index) => (
          <li key={index}>
            {OrderItem(item, data.campaignType)}
          </li>
        ))}
        {data && data.orderItemsGrocery && data.orderItemsGrocery.map((item, index) => (
          <li key={index}>
            {OrderItem(item, data.campaignType)}
          </li>
        ))}
        {data && data.partnerOrderItems && data.partnerOrderItems.map((item, index) => (
          <li key={index}>
            {OrderItem(item, data.campaignType)}
          </li>
        ))}
        {data.delivery &&
          <li>{DeliveryItem(data)}</li>
        }
        {data && data.campaign && data.campaign.discountCode &&
          <li>{CampaignItem(data)}</li>
        }
      </ul>
    </ProntoContainerTop>
  )

}


export default Order