import React from 'react'
import Placeholder from '../layout/Placeholder'
import Textbox from '../Form/Textbox'
import { useForm } from 'react-hook-form'
import styles from './VariantItemForm.module.css'

const VariantItemForm = ({ variantItem, onSave }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [editedPrice, setEditedPrice] = React.useState(
    variantItem.price > 0 ? (variantItem.price * 0.01).toFixed(2) : 0,
  )
  const [editedEan, setEditedEan] = React.useState(variantItem.ean || '')

  function onFormSubmit() {
    onSave(variantItem, {
      ...variantItem,
      ean: editedEan,
      price: parseFloat((editedPrice * 100).toFixed(2)),
    })
  }

  return (
    <div className={styles.container}>
      <h3>{variantItem.name}</h3>
      <form
        className="cateringForm"
        id="variantItemForm"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Textbox
          label="Pris (kr)"
          step="0.01"
          placeholder={Placeholder.SetPrice}
          id={`price-${variantItem.name}`}
          name={`price-${variantItem.name}`}
          type="number"
          value={editedPrice}
          onChange={(e) => setEditedPrice(e.target.value)}
          validationRegister={register}
          validation={{
            required: true,
            min: {
              value: 1,
              message: 'Pris måste vara större än 0',
            },
          }}
          errors={errors}
        />
        <Textbox
          label={Placeholder.Ean}
          placeholder={Placeholder.SetEan}
          id={`ean-${variantItem.name}`}
          name={`ean-${variantItem.name}`}
          value={editedEan}
          onChange={(e) => setEditedEan(e.target.value)}
          validationRegister={register}
          validation={{
            minLength: {
              value: 13,
              message: 'EAN måste vara 13 tecken',
            },
          }}
          errors={errors}
        />
        <button
          className={styles.saveButton}
          type="submit"
          form="variantItemForm"
        >
          {Placeholder.OK}
        </button>
      </form>
    </div>
  )
}

export default VariantItemForm
