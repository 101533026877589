import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox, {parseBool} from '../layout/Checkbox'
import {GetCssInput} from '../layout/Input'
import {ProntoContainerTop} from '../layout/Containers'
import { ClickButton,SaveButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'


const OpenHours = (props)=> {
  const mode = props.mode
  const history = props.history
  const referenceId = props.referenceId ? props.referenceId : props.match.params.referenceid
  const [data, setData] = useState( [])

  useEffect(() => {
    const fetchData = async () => {
      if(referenceId){
        const response = await getData(
          '/api/openhours/'+referenceId
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])
  function ShouldValidDateTime(name,value){
    if(name === "openTime" || name === "closeTime")
    {
      return ValidDateTime(value)    
    }
    return false
  }
  function ValidDateTime(value){
    if(value && value.match(/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/) && value.match("[0-9][0-9]:[0-9][0-9]")){
      return true
    }
    return false
  }
  function ShouldParseInt(name){
    if(name === 'active'){
      return true
    }
    return false
  }
  const GetDayName = (dayNumber) => {
    const days = ["Söndag", "Måndag", "Tisdag", "Onsdag","Torsdag","Fredag","Lördag"]

    return days[dayNumber]
  }
  const handlePostData = async () => {

    const url = data[0].id === "00000000-0000-0000-0000-000000000000" ? "/api/openhours/create" : "/api/openhours/edit"
    if(url){
      data.forEach(element => {
        if(element.active){
          var validTime = ValidDateTime(element.openTime) && ValidDateTime(element.closeTime)
          if(!validTime){
            alert(`$Dag:${element.day} har fel värde`)
            return
          }
        }
      
      })
      const response = await postData(
        url,data
      )
      return await response
     
    }else{
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    const values = event.target.name.split("__")
    const day = parseInt(values[0], 10)
    const name = values[1]
    const objIndex = data.findIndex(x => x.day === day)
    
    if(ShouldParseInt(name)){
      data[objIndex][name] = parseBool(event.target.value)
    }else if(ShouldValidDateTime(name,event.target.value)){
      data[objIndex][name] = event.target.value
    }
    setData(data)
  }

  const backLabel = props.match.path.includes('restaurants') ? Placeholder.Restaurants :
    props.match.path.includes('deliveryproviders') ? Placeholder.Delivery : ""


  return (
    <div>
      <BackButton editPage={true} backLabel={backLabel} {...props}>{Placeholder.OpenHours}</BackButton>

      <ProntoContainerTop>
        <ul className="no-style drop-shadow">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">Dag</div>
              <div className="list-item__element label hidden-down">Öppnar</div>          
              <div className="list-item__element label hidden-down">Stänger</div>     
              <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>     
            </div>
          </li>
            
          {data && data.map(item => (
            <li key={item.day} >
              <div className="list-item">
                <div className="list-item__element">{GetDayName(item.day)}</div>
                <div className="list-item__element">
                  <input className={GetCssInput(mode) + " input--small mt-10 mb-10"} type="text" onChange={handleInputChange} name={`${item.day}__openTime`} defaultValue={item.openTime} placeholder="08:00"/>
                  <span className="label form-label hidden-up ml-10 mt-10-mobile">Öppnar</span>   
                </div>
                <div className="list-item__element">
                  <input className={GetCssInput(mode) + " input--small mt-10 mb-10"} type="text" onChange={handleInputChange} name={`${item.day}__closeTime`} defaultValue={item.closeTime} placeholder="22:00"/>
                  <span className="label form-label hidden-up  ml-10 mt-10-mobile">Stänger</span>   
                </div>
                <div className="list-item__element list-item__element--s">
                  <Checkbox checked={item.active} name={`${item.day}__active`} defaultValue={item.active} handleEvent={handleInputChange}/>     
                  <span className="label form-label hidden-up">{Placeholder.Active}</span>   
                </div>      
              </div>
            </li>
          ))}
        </ul>
        <div className="form-section row mt-10">
          <SaveButton className="btn--black mr-10" title={Placeholder.SaveButton} onClick={handlePostData}/>
          <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
        </div>
      </ProntoContainerTop>
    </div>
  )
  
}


export default OpenHours

