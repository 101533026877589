
import React from 'react'
import { Link } from 'react-router-dom'
import {PlusIcon} from './Icons'
import showNotification from "../../utilis/Notifications"
import NotificationTypes from "../../utilis/NotificationTypes"



export const  SaveButton = (props) => {

  async function handelClick(){
    var history = props.history
    if(!isDisabled && props.onClick){
      var result = await props.onClick()
      var isSuccess = result && (result.status >= 200 && result.status <= 299)
      if(isSuccess){
        if(history && result.status == 204){
          history.goBack()
        }else{
          showNotification("Spara", "Ändringar har sparats" , NotificationTypes.SUCCESS)
        }
               
      }else{
        showNotification("Fel", "Gick inte att spara" , NotificationTypes.ERROR)
      }
           
    }
  }
  const isDisabled = props.disabled === true
  return <a className={`btn-control btn-control__link ${props.className} ${isDisabled ? "btn--disabled": ""}`} onClick={handelClick}>
    <div className="btn-control__content">
      {props.title}
    </div>
  </a>   
}

export const  ClickButton = (props) => {
  const isDisabled = props.disabled === true
  return <a className={`btn-control btn-control__link ${props.className} ${isDisabled ? "btn--disabled": ""}`} onClick={isDisabled ?  undefined: props.onClick}>
    <div className="btn-control__content">
      {props.title}
    </div>
  </a>   
}

export const  LinkButton = (props) => {
  return <Link className={`btn-control btn-control__link ${props.className}`} to={props.to}>
    <div className="btn-control__content">
      {props.title}
    </div>
  </Link>   
}
export const  ExternalLinkButton = (props) => {
  return <a className={`btn-control btn-control__link ${props.className}`} href={props.to} target="_blank" rel="noreferrer">
    <div className="btn-control__content">
      {props.title}
    </div>
  </a>   
}

export const  DownloadButton = (props) => {
  const token = props.token
  const formId = props.id ? props.id : `form-${Math.floor(Math.random() * Math.floor(100))}`
  function handelClick(){
    document.getElementById(formId).submit() 
  }
  return <a className={`btn-control btn-control__link ${props.className}`} onClick={handelClick}>
    <div className={`btn-control__content  ${props.titleClassName}`}>
      {props.title}
    </div>
    <form id={formId} action={props.to} method="post" target="_blank"> 
      <input type="hidden" name="jwtToken" value={token}/>
    </form>
  </a>
}


export const  AddButton = (props) => {
  return  <Link className="btn-control__link btn-control__link--red pl-10" to={props.to}>
    <div className="btn-control__content">
      <PlusIcon />
      {props.title}
    </div>
  </Link>
       
}

export const  TopAddButton = (props) => {
  return  <Link  className={props.className+" btn-control--absolut btn-control--absolut__top"} to={props.to}>
    <div className="btn-control__content">
      <PlusIcon className="mr-10"/>
      {props.title}
    </div>
  </Link>
}
