import React, { useState, useEffect } from 'react'
import {getData, postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer} from '../layout/Containers'
import Mode from '../../utilis/Mode'
import {GetCssInput} from '../layout/Input'
import {ClickButton,SaveButton} from '../layout/Button'
import Checkbox from '../layout/Checkbox'
import BackButton from '../backbutton/BackButton'
import SelectList from '../Form/SelectList'



const MenuItem = (props)=> {
  const mode = props.mode
  const history = props.history
  // const storeId = props.match.params.storeid;
  // const restaurantId = props.match.params.restaurantid;
  const menuId = props.match.params.menuid
  const itemId = props.match.params.itemid
  const [data, setData] = useState( {menuId: menuId, taxCode: 2})
  const [menu, setMenu] = useState({})
  const taxCodes = [{ id: 1, value: 1, name: '25 %' }, { id: 2, value: 2, name: '12 %' }, { id: 3, value: 3, name: '6 %' }]

  useEffect(() => {
    const fetchData = async () => {
      if(itemId){
        const response = await getData(
          '/api/menuitem/'+itemId
        )
        const result = await response
        setData(result)
      }

      if (menuId) {
        const menuResponse = await getData(
          '/api/menu/' + menuId
        )
        const menuResult = await menuResponse
        setMenu(menuResult)

      }
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/menuitem/edit'
    }
    if(mode === Mode.Create){
      return '/api/menuitem/create'
    }
    if(mode === Mode.Delete){
      return '/api/menuitem/delete'
    }

    return undefined
  }
  const handlePostData = async () => {

    const ean = data.Ean ? data.Ean : data.ean
    const eanLength = ean ? ean.toString().length : 0
    const isValidEan = eanLength === 0|| eanLength === 13
    if(!isValidEan){
      alert("Ean koden måste bestå av 13st eller 0st siffror!")
      return
    }
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response
    }else{
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    event.persist()
   
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  return (
    <div>
      <BackButton backLabel={menu.name} editPage={true} {...props}>{data.name}</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.name} placeholder={Placeholder.Name}/>
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="imageUrl" defaultValue={data.imageUrl} placeholder={Placeholder.ImageUrl}/>
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="orderingNumber" defaultValue={data.orderingNumber} placeholder={Placeholder.Sort}/>
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="category" defaultValue={data.category} placeholder={Placeholder.Category}/>
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="price" defaultValue={data.price} placeholder={Placeholder.Price} />
            {taxCodes &&
              <div>
                <SelectList
                  options={taxCodes}
                  optionValueField="value"
                  name="taxCode"
                  onChange={handleInputChange}
                  defaultOption="Välj momssats"
                  value={data.taxCode}
                />
              </div>
            }
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="ean" defaultValue={data.ean} placeholder={Placeholder.Ean} />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="nutrientFacts" defaultValue={data.nutrientFacts} placeholder="Näringsinnehåll" />
            <textarea name="description" className={GetCssInput(mode) + " input-textarea"}onChange={handleInputChange} value={data.description} placeholder={Placeholder.Description}></textarea>

            <div className="row">
              {data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              {!data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>

            <div className="row">
              <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
            </div>
          </div>  
        </div>
      </ProntoContainer>
    </div>
  )
  
}


export default MenuItem

